export const fetchKeys = {
  grading: {
    search: 'grading-search-product-search-input',
    searchStoreData: 'grading-search-store-data',
    summary: 'summary-get-price',
  },
  storeOperator: {
    stores: 'get-available-stores',
    tradeIns: 'get-my-tradeins',
  },
  phonePhoto: {
    getItemImage: 'get-item-image',
  },
  user: {
    data: 'get-user-data',
  },
  store: {
    authStoreData: 'get-auth-store-data',
  },
  product: {
    getBySibling: 'get-by-sibling',
  },
  tradeIn: {
    providerFind: 'find-trade-in-provider',
    contract: 'get-contract',
    find: 'find-trade-in',
  },
  functionalGrading: {
    getQrCode: 'get-qr-code',
    getDowngradedPrices: 'get-downgraded-prices',
  },
  item: {
    find: 'find-item',
  },
  file: {
    getFile: 'get-file',
  },
  bid: {
    getBid: 'get-best-bid',
  },
  survey: {
    getSurvey: 'get-survey',
  },
  dashboard: {
    getReport: 'get-report',
  },
  dailyTip: {
    get: 'get-daily-tip',
  },
  assessment: {
    create: 'create-assessment',
    get: 'get-assessment',
  },
  retailer: {
    get: 'get-retailer',
  },
};
