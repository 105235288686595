import { createContext, useContext, useEffect, useState } from 'react';

import { useAuth } from '@clerk/nextjs';

import { useTradeInContext } from '@/context/use-trade-in-provider';
import { useUserAuth } from '@/context/user-auth-provider';

import { BuyProduct } from '@@/Pricing/entities/buy-product';
import { useBestBid } from '@@/Pricing/hooks/use-bid-service';
import { BestBidResponse } from '@@/Pricing/services/bid-service';

import { STORAGE_KEYS, useLocalStorage } from '@/hooks/use-local-storage';

export type PricingContext = {
  bestBidData: BestBidResponse;
  setBestBidData: (data: BestBidResponse) => void;
  isBestBidLoading?: boolean;
  isBestBidWithError?: boolean;
  cleanUpPricing: () => void;
  buyProduct?: BuyProduct | null;
  setBuyProduct: (data: Partial<BuyProduct>) => void;
};

const pricingContext = createContext<PricingContext>({} as PricingContext);

export const PricingProvider: React.FCWC = ({ children }) => {
  const storage = useLocalStorage();
  const { interceptorId, isSignedIn } = useUserAuth();
  const { isLoaded } = useAuth();
  const { itemId, customerRefId } = useTradeInContext();

  const isAuthLoaded = isLoaded && isSignedIn && interceptorId !== null;

  const bestBidResponse = useBestBid({
    retry: 2,
    itemId: itemId,
    buyProductPrice: storage?.getItem(STORAGE_KEYS.DOJI_BUY_NEW_PRODUCT)?.price ?? null,
    isCustomerTradeIn: !!customerRefId,
    enabled: !!itemId && Boolean(customerRefId || (!customerRefId && isAuthLoaded)),
  });

  const [bestBidData, setBestBidData] = useState(bestBidResponse.bestBidData ?? ({} as BestBidResponse));
  const [buyProduct, setBuyProduct] = useState<BuyProduct | null>(
    storage?.getItem(STORAGE_KEYS.DOJI_BUY_NEW_PRODUCT) ?? null
  );

  useEffect(() => {
    if (bestBidResponse.isBestBidSuccess && bestBidResponse.bestBidData) {
      setBestBidData(bestBidResponse.bestBidData);
    }
  }, [bestBidResponse.isBestBidSuccess, bestBidResponse.bestBidData]);

  useEffect(() => {
    storage?.setItem(STORAGE_KEYS.DOJI_BUY_NEW_PRODUCT, buyProduct);
  }, [buyProduct]);

  const cleanUpPricing = () => {
    setBestBidData({} as BestBidResponse);
    storage?.removeItem(STORAGE_KEYS.DOJI_BUY_NEW_PRODUCT);
  };

  const value = {
    bestBidData,
    setBestBidData,
    isBestBidLoading: bestBidResponse.isBestBidLoading,
    isBestBidWithError: bestBidResponse.isBestBidError,
    cleanUpPricing,
    buyProduct,
    setBuyProduct,
  };

  return <pricingContext.Provider value={value}>{children}</pricingContext.Provider>;
};

export function usePricingContext() {
  return useContext(pricingContext);
}
