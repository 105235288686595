import { useQuery } from '@tanstack/react-query';

import BidService from '@@/Pricing/services/bid-service';

import { fetchKeys } from '@/constants/fetch-keys';

type Props = {
  itemId: string;
  newProductId?: string;
  buyProductPrice?: number;
  isCustomerTradeIn: boolean;
  retry?: number | boolean;
  enabled?: boolean;
};

export const useBestBid = ({
  itemId,
  buyProductPrice,
  newProductId,
  isCustomerTradeIn = false,
  retry = 3,
  enabled = true,
}: Props) => {
  const { data, isLoading, isError, isSuccess, refetch, isRefetching } = useQuery({
    queryKey: [fetchKeys.bid.getBid, itemId, isCustomerTradeIn, buyProductPrice, newProductId],
    queryFn: () =>
      isCustomerTradeIn
        ? BidService.publicBestBid(itemId as string)
        : BidService.getBestBid(itemId as string, buyProductPrice, newProductId),
    enabled: !!itemId && enabled,
    retry: retry,
  });

  return {
    bestBidData: data?.response,
    isBestBidLoading: isLoading,
    isBestBidError: isError,
    isBestBidSuccess: isSuccess,
    refetchBid: refetch,
    isRefetching: isRefetching,
  };
};
