export enum CLERK_VERIFICATION_STATUS {
  TRANSFERABLE = 'transferable',
  UNVERIFIED = 'unverified',
}

export enum OAUTH_ERROR {
  ACCOUNT_NOT_FOUND = 'external_account_not_found',
  ACCOUNT_EXISTS = 'external_account_exists',
}

export enum SIGN_UP_ERROR {
  NOT_ALLOWED_ACCESS = 'not_allowed_access',
  PASSWORD_PWNED = 'form_password_pwned',
  EMAIL_ALREADY_IN_USE = 'form_identifier_exists',
}

enum LOGIN_ERROR {
  PASSWORD_INCORRECT = 'form_password_incorrect',
  INVALID_FORM_FORMAT = 'form_param_format_invalid',
  ACCOUNT_NOT_FOUND = 'form_identifier_not_found',
}

export enum CODE_ERROR {
  INVALID_CODE = 'form_code_incorrect',
  EXPIRED_CODE = 'verification_expired',
}

type Error = {
  code: SIGN_UP_ERROR & LOGIN_ERROR;
};

export type ClerkError = {
  errors: Array<Error>;
};

export enum CLERK_TEMPLATES {
  DEFAULT = 'default',
}

export const ClerkErrorToMessage = {
  [SIGN_UP_ERROR.NOT_ALLOWED_ACCESS]: 'E-mail não foi convidado para nenhuma loja.',
  [SIGN_UP_ERROR.PASSWORD_PWNED]:
    'Esta senha já foi exposta em um vazamento de dados. Por favor, escolha uma diferente.',
  [SIGN_UP_ERROR.EMAIL_ALREADY_IN_USE]: 'E-mail já cadastrado.',
  [LOGIN_ERROR.PASSWORD_INCORRECT]: 'Senha incorreta.',
  [LOGIN_ERROR.INVALID_FORM_FORMAT]: 'Esse e-mail não é válido.',
  [LOGIN_ERROR.ACCOUNT_NOT_FOUND]: 'Conta com esse e-mail não encontrada.',
  [CODE_ERROR.INVALID_CODE]: 'Código não é válido.',
  [CODE_ERROR.EXPIRED_CODE]: 'Código expirado, reenvie um novo código.',
  [OAUTH_ERROR.ACCOUNT_NOT_FOUND]: 'Conta com esse e-mail não encontrada.',
  [OAUTH_ERROR.ACCOUNT_EXISTS]: 'E-mail já cadastrado.',
};

export const CLERK_OAUTH_MESSAGES = {
  [SIGN_UP_ERROR.NOT_ALLOWED_ACCESS]: 'Nenhum convite encontrado para',
};
