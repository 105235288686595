export const USER_ROLES = {
  ADMIN: 'ADMIN',
  STORE_OPERATOR: 'STORE_OPERATOR',
  STORE_OPERATOR_ADMIN: 'STORE_OPERATOR_ADMIN',
};

export enum EMAIL_STATUS {
  REGISTERED = 'REGISTERED',
  NOT_FOUND = 'NOT_FOUND',
  INVITED = 'INVITED',
}
