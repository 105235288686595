import { useMutation, useQuery } from '@tanstack/react-query';

import AssessmentService from '@@/ItemAndGrading/services/assessment-service';

import { fetchKeys } from '@/constants/fetch-keys';

export const useGetAssessment = () => {
  const { data, isLoading, isError, error, isSuccess, refetch } = useQuery({
    queryKey: [fetchKeys.assessment.get],
    queryFn: () => AssessmentService.getAssessment(),
    retry: 1,
  });

  return {
    assessmentData: data?.response,
    isLoading,
    isGetAssessmentError: isError,
    isGetAssessmentSuccess: isSuccess,
    errorAssessment: error,
    refetchAssessment: refetch,
  };
};

export const useCreateAssessment = () => {
  const { mutate, data, isPending, isError, isSuccess } = useMutation({
    mutationKey: [fetchKeys.assessment.create],
    mutationFn: () => AssessmentService.create(),
    retry: 1,
  });

  return {
    mutateAssessment: mutate,
    newAssessment: data?.response,
    isPending: isPending,
    isCreateAssessmentError: isError,
    isCreateAssessmentSuccess: isSuccess,
  };
};
