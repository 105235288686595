import { ApiResponse } from '@/@types/api-response';

import { Retailer } from '@@/StoreAndStoreOperator/entities/retailer';

import { tradeInApi } from '@/config/api';

export default class RetailerService {
  static async getRetailerSession() {
    const { data } = await tradeInApi.get<ApiResponse<Retailer>>(`/v1/session/retailers/me`);
    return data;
  }
}
