import { IntlProvider } from 'react-intl';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import localesMessages from '@/locales';

import { LOCALE } from '@/constants/locales';

import { AssessmentProvider } from './use-assessment-provider';
import { SessionAuthContextProvider } from './use-session-provider';

export const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

type Props = {
  queryClient?: QueryClient;
};

const SessionProvider: React.FCWC<Props> = ({ children, queryClient }) => {
  return (
    <IntlProvider locale={LOCALE.BR} messages={localesMessages[LOCALE.BR]}>
      <QueryClientProvider client={queryClient ?? client}>
        <SessionAuthContextProvider>
          <AssessmentProvider>{children}</AssessmentProvider>
        </SessionAuthContextProvider>
      </QueryClientProvider>
    </IntlProvider>
  );
};

export default SessionProvider;
