import { createContext, useContext, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { isEmpty } from 'ramda';

import { QueryObserverResult, RefetchOptions, useQuery } from '@tanstack/react-query';

import { ApiResponse } from '@/@types/api-response';

import { Assessment } from '@@/ItemAndGrading/entities/assessment';
import { Item } from '@@/ItemAndGrading/entities/item';
import { useGetAssessment } from '@@/ItemAndGrading/hooks/use-assessment-service';
import { Retailer } from '@@/StoreAndStoreOperator/entities/retailer';
import RetailerService from '@@/StoreAndStoreOperator/services/retailer-service';

import { STORAGE_KEYS, useLocalStorage } from '@/hooks/use-local-storage';

import { fetchKeys } from '@/constants/fetch-keys';

import FullScreenSpinner from '@/components/full-screen-spinner';

type AssessmentContext = {
  retailer?: { data?: Retailer; isLoading: boolean; isError: boolean };
  assessment?: {
    data?: Assessment;
    isLoading: boolean;
    refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<ApiResponse<Assessment>, Error>>;
    isSuccess: boolean;
    isError: boolean;
    error?: Error | null;
  };
  itemId?: string;
  itemData: Item;
  setItemData: (item: Item) => void;
  productName: string;
};

const assessmentContext = createContext<AssessmentContext>({} as AssessmentContext);

export const AssessmentProvider: React.FCWC = ({ children }) => {
  const storage = useLocalStorage();
  const { query } = useRouter();
  const [itemData, setItemData] = useState<Item>({} as Item);

  const {
    assessmentData,
    isLoading,
    refetchAssessment,
    isGetAssessmentSuccess,
    errorAssessment,
    isGetAssessmentError,
  } = useGetAssessment();

  const {
    data: retailerData,
    isLoading: isRetailerLoading,
    isError: isRetailerError,
  } = useQuery({
    queryKey: [fetchKeys.retailer.get],
    queryFn: () => RetailerService.getRetailerSession(),
  });

  useEffect(() => {
    if (!isEmpty(itemData)) storage?.setItem(STORAGE_KEYS.DOJI_ITEM_ID, itemData.id);
  }, [itemData]);

  const values = {
    retailer: {
      data: retailerData?.response,
      isLoading: isRetailerLoading,
      isError: isRetailerError,
    },
    assessment: {
      data: assessmentData,
      isLoading: isLoading,
      refetch: refetchAssessment,
      isSuccess: isGetAssessmentSuccess,
      error: errorAssessment,
      isError: isGetAssessmentError,
    },
    itemData,
    setItemData,
    itemId: storage?.getItem(STORAGE_KEYS.DOJI_ITEM_ID) ?? assessmentData?.itemId ?? itemData?.id,
    productName:
      itemData?.product?.model?.fullModelName ?? storage?.getItem(STORAGE_KEYS.DOJI_PRODUCT_NAME) ?? query?.product,
  };

  if (!retailerData?.response) return <FullScreenSpinner isVisible />;

  return <assessmentContext.Provider value={values}>{children}</assessmentContext.Provider>;
};

export const useAssessment = () => useContext(assessmentContext);
